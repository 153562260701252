import { Action, combineReducers, Store } from 'redux';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import mapReducer from './redux/reducers/mapReducer/index';
import navigationReducer from './redux/reducers/navigationReducer/index';
import aircraftSlice from './redux/slice/aircraft';
import tracksSlice from './redux/slice/tracks';
import loadingSlice from './redux/slice/loading';
import insightsSlice from './redux/slice/insights';
import safetyInsightsSlice from './redux/slice/safetyInsights';
import eventsSlice from './redux/slice/events';
import flightExplorerSlice from './redux/slice/flightExplorer';
import historyTracksSlice from './redux/slice/historyTracks';
import { UserData } from './common/api/spidertracks-sdk/types/UserData';
import {
  EventState,
  FlightExplorerState,
  HistoryTracksState,
  Point,
  SosNotification
} from './redux/types';

export const history = createBrowserHistory();

let store: Store;
const middleware = [routerMiddleware(history)];
const reducer = combineReducers({
  router: connectRouter(history),
  mapReducer,
  navigationReducer,
  aircraft: aircraftSlice.reducer,
  tracks: tracksSlice.reducer,
  loading: loadingSlice.reducer,
  insights: insightsSlice.reducer,
  safetyInsights: safetyInsightsSlice.reducer,
  events: eventsSlice.reducer,
  flightExplorer: flightExplorerSlice.reducer,
  historyTracks: historyTracksSlice.reducer
});

export const getStore = (options?: Partial<ConfigureStoreOptions>) =>
  configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        // immutableCheck: { ignoredPaths: ['tracks.all'] },
        immutableCheck: false,
        serializableCheck: false
      }).concat(...middleware),
    devTools: {
      actionSanitizer: action =>
        action.type == 'flightExplorer/setSelectedTrackFlightData'
          ? { ...action, payload: '<<LONG_BLOB>>' }
          : action
    },
    ...options
  });

store = getStore();

// NOTE: if we overwrite this here, it could have unpredictable effects on other tests. Prefer use
// of `getStore`.
export const setTestStore = (testStore: Store) => {
  store = testStore;
};

export default store;

type Modify<T, R> = Omit<T, keyof R> & R;

export type FullState = Modify<
  ReturnType<typeof reducer>,
  {
    mapReducer: {
      mapView: {
        googleView: string; //'roadmap',
        skyVectorView: string;
      };
      selectedFilter: undefined | string;
      weatherLayer: undefined | string;
      pointGroupingOption: string;
      selectedMarkerPoint: undefined | Point;
      kmlData: any[];
      activeKmls: any[];
      uiSettings: { clusterer: boolean };
      sosData: any[];
      mapStatus: {
        loading: boolean;
        loaded: boolean;
      };
      aircraftListVisible: boolean;
      mapZoomLevel: undefined | number;
    };
    navigationReducer: {
      exclusions: any[];
      spidertxtCount: number;
      pendingSpidertxtNotificationCount: number;
      favourites: {
        id: string;
        zoom_level: number;
        lat: string;
        lng: string;
      }[];
      userData: UserData;
      sosNotifications: SosNotification[];
    };
    events: EventState;
    flightExplorer: FlightExplorerState;
    historyTracks: HistoryTracksState;
  }
>;

// Intersection of actions from each duck
export type AllActions = Action<string>;
// The type of my store (I sometimes need to reference this in things like unit tests)
export type StandardStore = typeof store;
// The type of my dispatch() method (for use in places like `mapDispatchToProps()`
export type StandardDispatch = ThunkDispatch<FullState, null, AllActions>;

// The return type of a thunk action creator
export type StandardThunk<TReturnType = any> = ThunkAction<
  // In our project, we make all our thunks asynchronous.
  Promise<TReturnType>,
  FullState,
  null,
  AllActions
>;
