import { ServiceAbstract } from '../../ServiceAbstract';
import { Amplify } from '@spidertracks/common';

interface APIConnectionResponse {
  results: [
    {
      appClientId: string;
      createdAt: string;
      id: string;
      orgId: string;
      status: string;
    }
  ];
}
export class IntegrationService extends ServiceAbstract {
  public async initialiseConnection(
    organisationName: string,
    organisationId: string,
    appClientId: string,
    thirdPartyAuthURL: string | undefined
  ): Promise<object> {
    return Amplify.API.get(
      'api.spidertracks.io-authenticated',
      'internal/integrations/connect?organisationName=' +
        organisationName +
        '&organisationId=' +
        organisationId +
        '&appClientId=' +
        appClientId +
        (thirdPartyAuthURL !== undefined ? `&thirdPartyAuthURL=${thirdPartyAuthURL}` : ''),
      {}
    );
  }

  public async resetConnection(connectionId: string): Promise<object> {
    return Amplify.API.post(
      'api.spidertracks.io-authenticated',
      `internal/integrations/reset/${connectionId}`,
      {}
    );
  }

  public async getIntegrationStatus(organisationId: string): Promise<APIConnectionResponse> {
    return Amplify.API.get(
      'api.spidertracks.io-authenticated',
      `organisations/${organisationId}/integrations`,
      {}
    );
  }
}
