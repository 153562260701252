import React from 'react';
import { Table } from 'antd';
import { TableEventListeners, ColumnProps } from 'antd/lib/table';
export interface TableRow {
  key: string;
  category: string;
  people: string;
  peopleToolTip: string;
  notificationType: string;
}

export interface GeofenceNotificationTableRow extends TableRow {
  aircraft: string;
  aircraftToolTip: string;
  geofence: string;
  geofenceToolTip: string;
}

export interface ExceedenceNotificationTableRow extends TableRow {
  exceedence: string;
  exceedenceToolTip: string;
  exceedenceRule: string;
  exceedenceRuleToolTip: string;
}

export interface NotificationTableProps {
  columns: ColumnProps<ExceedenceNotificationTableRow | GeofenceNotificationTableRow>[];
  notificationsData?: ExceedenceNotificationTableRow[] | GeofenceNotificationTableRow[];
  loading: boolean;
  onRow: (
    record: ExceedenceNotificationTableRow | GeofenceNotificationTableRow
  ) => TableEventListeners;
}

export const NotificationsTable: React.FC<NotificationTableProps> = ({
  columns,
  notificationsData,
  loading,
  onRow
}) => {
  return (
    <React.Fragment>
      <Table
        bordered
        dataSource={notificationsData}
        columns={columns}
        loading={loading}
        onRow={onRow}
      />
    </React.Fragment>
  );
};

NotificationsTable.displayName = 'NotificationsTable';

export default NotificationsTable;
