import React from 'react';
import SisenseFilters from './filters/SisenseFilters';
import InsightsDashboardExportButton from './InsightsDashboardExportButton';
import { PageHeader } from 'antd';

export const SafetyInsightsHeader = () => {
  return (
    <div className="mb-1" style={{ paddingLeft: '20px' }}>
      <PageHeader
        className="site-page-header"
        style={{ paddingLeft: '0px' }}
        title="Safety Insights"
        subTitle="View and manage safety flight insights"
      />
      <SisenseFilters
        datasource="flightdata_prod"
        aircraftdim="[enriched_safety_event.aircraftRegistration]"
        eventTypeDim="[enriched_safety_event.formattedEventType]"
        severityTypeDim="[enriched_safety_event.severity]"
      />
      <InsightsDashboardExportButton />
    </div>
  );
};

SafetyInsightsHeader.displayName = 'SafetyInsightsHeader';
export default SafetyInsightsHeader;
