import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input } from 'antd';

const formItemStyle = { marginBottom: '1px' };

const AirmaestroFormInput = ({
  name,
  label,
  getFieldDecorator,
  validationRules = [{ required: false }],
  placeholder,
  handleFieldChange,
  disabled = false
}) => (
  <Form.Item name={name} label={label} style={formItemStyle}>
    <Row>
      <Col span={16}>
        {getFieldDecorator(name, {
          rules: validationRules
        })(
          <Input
            name="thirdPartyUrl"
            addonBefore="https://"
            addonAfter=".airmaestro.com.au"
            autoComplete="off"
            placeholder={placeholder}
            style={{ width: '320px', height: '40px' }}
            onChange={handleFieldChange}
            disabled={disabled}
          />
        )}
      </Col>
    </Row>
  </Form.Item>
);

AirmaestroFormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  getFieldDecorator: PropTypes.func.isRequired,
  validationRules: PropTypes.array,
  placeholder: PropTypes.string,
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default AirmaestroFormInput;
