import React, { useState } from 'react';
import { getInstance } from '../../../common/api/spidertracks-sdk';
import { Modal, Tooltip, notification } from 'antd';
import { MapOutlined, TableChartOutlined, TimelineOutlined } from '@material-ui/icons';
import { getSpinner } from '../../../util';
import './styles.scss';
import { DownloadTracksRequest } from '../../../common/api/spidertracks-sdk/types/DownloadTracksRequest';

type ComponentProps = {
  visible: boolean;
  trackIds: string[];
  serialNumber?: string;
  bootCount?: number;
  downloadLimit: number;
  requestClose: () => void;
  downloadStartedCallback?: () => void;
  downloadCompletedCallback?: () => void;
};

export const DownloadTracks: React.FC<ComponentProps> = ({
  visible,
  trackIds,
  serialNumber,
  bootCount,
  downloadLimit,
  requestClose,
  downloadStartedCallback,
  downloadCompletedCallback
}: ComponentProps) => {
  const [csvSummary, setCSVSummary] = useState(false);
  const [csvDetail, setCSVDetail] = useState(false);
  const [kml, setKML] = useState(false);

  const SpidertracksSDK = getInstance();

  const detailDisabled = trackIds.length > downloadLimit;
  const disabledButtonText = `You can not download this file type as you have selected a large number of flights. Please select up to ${downloadLimit} flights.`;

  const downloadTracks = async (type: string) => {
    const downloadNotificationKey = 'download-notification';
    // Used by calling components which manage their own notifications, like in history page where you are expected to have multiple downloads run parallelly.
    const callerManagedNotifications = downloadStartedCallback && downloadCompletedCallback;

    if (!callerManagedNotifications) {
      const downloadMessage = trackIds.length > 1 ? 'Downloading tracks' : 'Downloading track';
      notification.open({
        placement: 'topRight',
        message: downloadMessage,
        description: 'Your download is being processed, it will start shortly.',
        key: downloadNotificationKey,
        duration: 0,
        icon: getSpinner({})
      });
    }

    const downloadTracksRequest: DownloadTracksRequest = {
      trackIds: trackIds.join(','),
      includeFlightLogs: true,
      type,
      serialNumber,
      bootCount
    };

    downloadStartedCallback && downloadStartedCallback();

    await SpidertracksSDK.downloadTracks(downloadTracksRequest);

    downloadCompletedCallback && downloadCompletedCallback();

    if (!callerManagedNotifications) {
      notification.close(downloadNotificationKey);
    }
  };

  return (
    <Modal
      className="download-modal"
      title={
        <div className="download-modal_title-container">
          <p className="download-modal_title-title">Download Flights</p>
          <p className="download-modal_title-subtitle">
            Select the file types you wish to download
          </p>
        </div>
      }
      okText="Download"
      style={{ border: 0 }}
      maskStyle={{ border: 0 }}
      bodyStyle={{ border: 0 }}
      visible={visible}
      onCancel={requestClose}
      destroyOnClose={true}
      okButtonProps={{ disabled: !csvSummary && !csvDetail && !kml }}
      onOk={() => {
        csvSummary && downloadTracks('csvsummary');
        csvDetail && downloadTracks('csv');
        kml && downloadTracks('kml');
        setCSVSummary(false);
        setCSVDetail(false);
        setKML(false);
        requestClose();
      }}
    >
      <div className="download-modal_grid">
        <div className="download-modal_option">
          <div className="download-modal_icon-container">
            <TableChartOutlined className="download-modal_icon" />
          </div>
          <span onClick={() => setCSVSummary(!csvSummary)}>
            <input
              data-testid="csv-summary"
              className="download-modal_checkbox"
              type="checkbox"
              checked={csvSummary}
              onChange={() => setCSVSummary(!csvSummary)}
            />
            CSV summary file
          </span>
          <span className="download-modal_option-description">
            Download track summary in CSV format
          </span>
        </div>

        <div className="download-modal_option">
          <div className={`download-modal_icon-container${detailDisabled ? '--disabled' : ''}`}>
            <TimelineOutlined className="download-modal_icon" />
          </div>
          <Tooltip
            placement="bottom"
            title={disabledButtonText}
            {...(detailDisabled ? {} : { visible: false })}
          >
            <span
              className={`download-modal_checkbox-label${detailDisabled ? '--disabled' : ''}`}
              onClick={() => {
                if (!detailDisabled) {
                  setCSVDetail(!csvDetail);
                }
              }}
            >
              <input
                data-testid="csv-detail"
                className="download-modal_checkbox"
                type="checkbox"
                checked={csvDetail}
                disabled={detailDisabled}
                onChange={() => {
                  if (!detailDisabled) {
                    setCSVDetail(!csvDetail);
                  }
                }}
              />
              CSV detail file
            </span>
          </Tooltip>
          <span className="download-modal_option-description">
            Download track details in CSV format
          </span>
        </div>

        <div className="download-modal_option">
          <div className={`download-modal_icon-container${detailDisabled ? '--disabled' : ''}`}>
            <MapOutlined className="download-modal_icon" />
          </div>
          <Tooltip
            placement="bottom"
            title={disabledButtonText}
            {...(detailDisabled ? {} : { visible: false })}
          >
            <span
              className={`download-modal_checkbox-label${detailDisabled ? '--disabled' : ''}`}
              onClick={() => {
                if (!detailDisabled) {
                  setKML(!kml);
                }
              }}
            >
              <input
                data-testid="kml"
                className="download-modal_checkbox"
                type="checkbox"
                checked={kml}
                disabled={detailDisabled}
                onChange={() => {
                  if (!detailDisabled) {
                    setKML(!kml);
                  }
                }}
              />
              KML file
            </span>
          </Tooltip>
          <span className="download-modal_option-description">
            Download track details in KML format
          </span>
        </div>
      </div>
    </Modal>
  );
};
