/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import React, { FunctionComponent } from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { polyfill } from 'es6-promise';
import Loadable from 'react-loadable';
import { Spin, Row, Col } from 'antd';
import { isAuthenticated, Amplify, cognitoSignIn } from '@spidertracks/common';
import store, { history } from './store';
import Routes from './routes/routes';
import * as serviceWorker from './serviceWorker';
import 'assets/scss/global.scss';
import { getInstance, initializeInstance } from './common/api/spidertracks-sdk';
import * as firebase from 'firebase/app';
import { featureConfigLocalStoragePath } from './hooks/useFeatureConfig';

polyfill();
const target = document.getElementById('root');

const ConfigWaiter = Loadable({
  loader: () =>
    Promise.all([
      fetch('/config.json')
        .then(config => config.json())
        .then(config => {
          window.env = config;

          return window.env;
        })
        .then(config => {
          try {
            firebase.initializeApp(config.firebaseConfig);
            // firebase.performance();
            initializeInstance({
              baseUrl: config.STL_NODE_ORIGIN,
              isPublic: !isAuthenticated()
            });
          } catch (err) {
            console.error(err);
          }

          return config;
        })
        .then(config => {
          Amplify.configure({
            API: {
              endpoints: [
                {
                  ...config.api,
                  name: 'unauthenticated'
                },
                {
                  ...config.api,
                  name: 'authenticated',
                  timeout: 300000,
                  custom_header: async () => {
                    return {
                      Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                        .getIdToken()
                        .getJwtToken()}`
                    };
                  }
                },
                {
                  ...config.newApi,
                  name: 'api.spidertracks.io-authenticated',
                  custom_header: async () => {
                    return {
                      Authorization: `Bearer ${(await Amplify.Auth.currentSession())
                        .getIdToken()
                        .getJwtToken()}`
                    };
                  }
                }
              ]
            }
          });
          return Amplify.API.get('unauthenticated', 'amplifyConfig');
        })
        .then(
          async (amplifyConfig): Promise<FunctionComponent> => {
            Amplify.configure(amplifyConfig.customAuth);
            const hash = window.localStorage.getItem('hash');
            if (hash) {
              const usernamePassword = atob(hash.split(' ')[1]).split(':');
              await cognitoSignIn(usernamePassword[0], usernamePassword[1]);

              const featureFlags = await getInstance().getFeatureFlags();
              localStorage.setItem(featureConfigLocalStoragePath, JSON.stringify(featureFlags));
            }

            return () => null;
          }
        )
        .catch(error => {
          if (error.code == 'NotAuthorizedException') {
            // User has reset the password, but browser cache is not cleared
            localStorage.clear();
            location.reload();
          }
          console.error(error);
          throw error;
        })
    ]),

  loading: props => {
    if (props.error) {
      // When the loader has errored
      return (
        <div>
          Error! <button onClick={props.retry}>Retry</button>
        </div>
      );
    } else if (props.timedOut) {
      // When the loader has taken longer than the timeout
      return (
        <div>
          Taking a long time... <button onClick={props.retry}>Retry</button>
        </div>
      );
    } else if (props.pastDelay) {
      // When the loader has taken longer than the delay
      return (
        <Row type="flex" justify="space-around" align="middle" style={{ height: '100vh' }}>
          <Col>
            <Spin size="large" />
          </Col>
        </Row>
      );
    } else {
      // When the loader has just started
      return null;
    }
  },
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
    );
  }
});

ReactDOM.render(<ConfigWaiter />, target);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

declare global {
  interface Window {
    env: any;
    google: any;
    gmap: any;
    [key: string]: any;
  }
}
