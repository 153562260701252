/* eslint-disable no-undef */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from '@spidertracks/common';
import { PublicRoute } from './PublicRoute';
import LoginLayout from '../layout/Login';
import { MainLayoutPrivateRoute } from './MainLayoutPrivateRoute';
import { MainLayoutPublicRoute } from './MainLayoutPublicRoute';
import { PrivateRoute } from './PrivateRoute';
import PublicFlyingPage from '../pages/public-flying';
import SharedTracks from '../pages/public-flying/SharedTracks';
import { FourOhFour } from '../pages/404';

import NgContainer from '../packages/ng/containers/NgContainer';
import Analytics from 'react-router-ga';

import OrganisationRoot from '../pages/organisation/OrganisationRoot';
import HistoryRoot from '../pages/history/HistoryRoot';
import {
  ORGANISATION,
  HISTORY,
  INSIGHTS,
  INSIGHTS_SSO_LOGIN,
  FLIGHT,
  SAFETYINSIGHTS
} from '../common/constants';
import { InsightsRedirect } from '../pages/insights/InsightsRedirect';
import InsightsRoot from '../components/Insights/Insights';
import SafetyInsights from '../components/SafetyInsights/safetyInsights';
import FlyingPage from '../pages/flying';
import FlightPage from '../pages/flying/FlightPage';
import onSuccessfulLogin from './onSuccessfulLogin';

const Routes = () => (
  <Analytics id={window.env.STL_GOOGLE_ANALYTICS_TRACKING}>
    <Switch>
      <Route
        path="/login"
        render={({ history, location }) => (
          <LoginLayout>
            <PublicRoute
              path="/"
              component={Login}
              authCallback={onSuccessfulLogin(history, location)}
            />
          </LoginLayout>
        )}
      />

      <MainLayoutPublicRoute path="/public/e/:token" component={SharedTracks} />
      <MainLayoutPublicRoute path="/public/:organisationSlug" component={PublicFlyingPage} />
      <PrivateRoute path={INSIGHTS_SSO_LOGIN} component={InsightsRedirect} />

      <MainLayoutPrivateRoute path="/">
        <Route exact path="/" component={FlyingPage} />
        <Route path={HISTORY} component={HistoryRoot} />
        <Route path={INSIGHTS} component={InsightsRoot} />
        <Route path={ORGANISATION} component={OrganisationRoot} />
        <Route path={SAFETYINSIGHTS} component={SafetyInsights} />
        <Route exact path={`${FLIGHT}/:trackId`} component={FlightPage} />
        <Route exact path={`${FLIGHT}/:serialNumber/:bootcount`} component={FlightPage} />
        {/* Redirects old history page URL to the new history page.  */}
        <Route exact path="/page/history">
          <Redirect to="/history" />
        </Route>
        <Route exact path="/page/:name" component={NgContainer} />
      </MainLayoutPrivateRoute>

      <Route path="**" component={FourOhFour} />
    </Switch>
  </Analytics>
);

export default Routes;
