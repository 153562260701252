import {
  ChartSeries,
  FlightExplorer3DReplayFeatures,
  FlightExplorerState,
  PanelVisibility,
  Point
} from '../types';
import { FullState } from '../../store';
import { createSelector } from 'reselect';
import { CompleteFlightDataSamples, FlightDataSample } from '../../types/FlightData';

export const getFlightExplorer = (state: FullState): FlightExplorerState => state.flightExplorer;

export const getDisplayTimezone = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): string => flightExplorer.displayTimezone
);

export const getIsPlaying = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): boolean => flightExplorer.isPlaying
);

export const getPlaybackSpeed = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): number => flightExplorer.playbackSpeed
);

export const getElapsedTime = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): number => flightExplorer.elapsedTime
);

export const getUpdateSelectedDisplayPoint = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): boolean => flightExplorer.updateSelectedDisplayPoint
);

export const getSelectedDisplayPoint = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): Point | null => flightExplorer.events.selectedDisplayPoint
);

export const getPanelVisibility = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): Record<string, PanelVisibility> =>
    flightExplorer.panelVisibility
);

export const get3dReplayFeatures = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): FlightExplorer3DReplayFeatures =>
    flightExplorer['3dReplayFeatures']
);

export const getSelectedTrackFlightData = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): CompleteFlightDataSamples =>
    flightExplorer.selectedTrackFlightData
);

export const getSelectedTrackFlightDataRaw = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): FlightDataSample[] =>
    flightExplorer.selectedTrackFlightData.raw
);

export const getSelectedTrackFlightDataSmoothed = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): FlightDataSample[] =>
    flightExplorer.selectedTrackFlightData.smoothed
);

export const getChartSeries = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): ChartSeries => flightExplorer.chartSeries
);

// TODO remove once FlightExplorer GA
export const getDataSource = createSelector(
  [getFlightExplorer],
  (flightExplorer: FlightExplorerState): string => flightExplorer.dataSource
);
