import { UserData } from '../../../../redux/types';

export type CHART_AXIS = 'LEFT' | 'RIGHT';

interface Parameter {
  key: string;
  name: string;
  displayName: string;
  tooltip?: string;
  requiresAHRS?: boolean;
  requiresFlightData?: boolean;
}

interface ParameterGroup {
  key: string;
  name: string;
  members: Parameter[];
  defaultSelectedMembers: string[];
}

const DEFAULT_PARAMETER_GROUP_NAME_LEFT = 'Altitude';
const DEFAULT_PARAMETER_GROUP_NAME_RIGHT = 'Speed';

// Make sure that there is at least one default selected member for each parameter group. This is so that Highcharts requires at least one series in left axis.
const PARAMETER_GROUPS: ParameterGroup[] = [
  {
    key: 'altitude',
    name: 'Altitude',
    defaultSelectedMembers: ['AMSL'],
    members: [
      {
        key: 'amsl',
        name: 'AMSL',
        displayName: 'Altitude AMSL',
        tooltip: 'Height Above Mean Sea Level'
      },
      {
        key: 'agl',
        name: 'AGL',
        displayName: 'Altitude AGL',
        tooltip: 'Height Above Ground Level',
        requiresFlightData: true
      },
      {
        key: 'terrain',
        name: 'Terrain',
        displayName: 'Terrain',
        requiresFlightData: true
      }
    ]
  },
  {
    key: 'speed',
    name: 'Speed',
    defaultSelectedMembers: ['Ground Speed'],
    members: [
      {
        key: 'groundSpeed',
        name: 'Ground Speed',
        displayName: 'Ground Speed',
        tooltip: 'From GPS'
      },
      {
        key: 'verticalSpeed',
        name: 'Vertical Speed',
        displayName: 'Vertical Speed',
        tooltip: 'From GPS',
        requiresAHRS: true,
        requiresFlightData: true
      }
    ]
  },
  {
    key: 'attitude',
    name: 'Attitude',
    defaultSelectedMembers: ['Pitch'],
    members: [
      {
        key: 'pitch',
        name: 'Pitch',
        displayName: 'Pitch',
        requiresAHRS: true,
        requiresFlightData: true
      },
      {
        key: 'roll',
        name: 'Roll',
        displayName: 'Roll',
        requiresAHRS: true,
        requiresFlightData: true
      }
    ]
  }
];

export const getParameterGroupMenuItems = (isAHRSAllowed: boolean, hasFlightData: boolean) => {
  const parameterGroupsFiltered = PARAMETER_GROUPS.filter(group => {
    if (isAHRSAllowed && hasFlightData) {
      return true;
    }

    const membersWithoutAhrsRequirement = group.members.filter(member => !member.requiresAHRS);
    if (membersWithoutAhrsRequirement.length === 0) {
      return false;
    }

    const membersWithoutFlightDataRequirement = group.members.filter(
      member => !member.requiresFlightData
    );
    if (membersWithoutFlightDataRequirement.length === 0) {
      return false;
    }

    return true;
  });

  return parameterGroupsFiltered.map(group => {
    return { key: group.key, label: group.name };
  });
};

export const getParameterGroupByName = (parameterGroupName: string) => {
  return PARAMETER_GROUPS.find(group => group.name === parameterGroupName);
};

export const getCustomizedParameterGroupName = (parameterGroupName: string, userData: UserData) => {
  switch (parameterGroupName) {
    case 'Altitude':
      return `Altitude (${userData.altitudeUnit})`;
    case 'Speed':
      return `Speed (${userData.speedUnit})`;
    case 'Attitude':
      return `Attitude (degrees)`;
    default:
      return parameterGroupName;
  }
};

export const getDisplayNameForParameter = (parameterName: string) => {
  for (const parameterGroup of PARAMETER_GROUPS) {
    const parameter = parameterGroup.members.find(member => member.name === parameterName);
    if (parameter) {
      return parameter.displayName;
    }
  }

  return parameterName;
};

export const getGroupNameForParameter = (parameterName: string) => {
  for (const group of PARAMETER_GROUPS) {
    if (group.members.find(member => member.name === parameterName)) {
      return group.name;
    }
  }

  return undefined;
};

export const getParameterByDisplayName = (displayName: string) => {
  for (const group of PARAMETER_GROUPS) {
    const parameter = group.members.find(member => member.displayName === displayName);
    if (parameter) {
      return parameter.name;
    }
  }

  return undefined;
};

export const getDefaultSelectedMembers = (chartAxis: CHART_AXIS) => {
  const defaultParameterGroupNameForAxis =
    chartAxis === 'LEFT' ? DEFAULT_PARAMETER_GROUP_NAME_LEFT : DEFAULT_PARAMETER_GROUP_NAME_RIGHT;

  const defaultParameterGroup = getParameterGroupByName(defaultParameterGroupNameForAxis);
  if (defaultParameterGroup) {
    return defaultParameterGroup.defaultSelectedMembers;
  }

  return [];
};
