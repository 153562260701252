import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAircraftData, getSelectedTracks } from '../../redux/selectors/aircraftData';
import {
  getDateFormat,
  getDistanceUnit,
  getTimezone,
  getUserData
} from '../../redux/selectors/userData';
import { getFlightReportDisplayPoints } from '../../redux/selectors/eventData';
import { getDisplayTimezone, getPanelVisibility } from '../../redux/selectors/flightExplorer';
import { reset, setPanelVisibility } from '../../redux/slice/flightExplorer';
import { PanelVisibility } from '../../redux/types';
import FlightTracklogViewer from '../../components/FlightExplorer/FlightTracklogViewer';
import ControlsPanel from '../../components/FlightExplorer/ControlsPanel';
import {
  dateFormatter,
  formatSecondsToHoursDecimal,
  formatStandardDateStrings,
  fromTimestamp
} from '../../helpers/formatTime';
import { convertMetresTo } from '../../components/Flying/Map/utils/helper';
import { FlightDetailPanel } from '../../components/FlightExplorer/FlightDetailPanel';
import './styles.scss';
import EventsTimeline from '../../components/FlightExplorer/EventsTimeline';
import { getIsActive } from '../../redux/slice/tracks';
import { getHistoryFilterConfig } from '../../redux/selectors/historyTrackData';
import { useWindowHeight } from '@react-hook/window-size';
import { useFeatureFlag, useFlightStatus } from '../../hooks';
import { useFlightDataService } from '../../hooks/useFlightDataService';
import FlightExplorerChart from '../../components/FlightExplorer/FlightExplorerChart';
import { FlightDataFlags } from '../../types/FlightData';
import { FullState } from '../../store';

const getShortenedDistanceUnit = (distanceUnit: string) => {
  switch (distanceUnit.toLowerCase()) {
    case 'kilometres':
      return 'km';
    case 'miles':
      return 'mi';
    case 'nautical miles':
    default:
      return distanceUnit;
  }
};

// Shows a vertical scrollbar in the flight explorer view if the window height is below the following value.
export const WINDOW_HEIGHT_VERTICAL_SCROLLBAR_THRESHOLD = 1050;

const FlightExplorerPage = (props: {
  flags: {
    useNewFlightDataService: boolean;
    preferSpiderSource: boolean;
    insightsEnabled: boolean;
  };
}) => {
  const dispatch = useDispatch();
  const windowHeight = useWindowHeight();

  const selectedTracks = useSelector(
    getSelectedTracks,
    (prev, next) => prev.length === next.length // to prevent this component and children from unnecessary re-rendering when the Cesium clock ticks
  );
  const timezone = useSelector(getTimezone);
  const displayTimezone = useSelector(getDisplayTimezone);
  const dateFormat = useSelector(getDateFormat);
  const distanceUnit = useSelector(getDistanceUnit);
  const points = useSelector(
    getFlightReportDisplayPoints,
    (prev, next) => prev !== undefined && next !== undefined && prev.length === next.length
  );

  const [tracksWithStatus] = useFlightStatus(selectedTracks, props.flags, true);
  const track = selectedTracks[0];

  const userData = useSelector(getUserData);
  const { orgs = [] } = userData;
  const userOrgIds = orgs.reduce((acc: string[], o) => acc.concat(o.org.id), []);
  const useNewFlightDataService = useFeatureFlag('use-new-flightdata-service', userOrgIds);
  const insightsEnabled = useFeatureFlag('insights', userOrgIds);
  const preferSpiderSource = useFeatureFlag('prefer-spider-source-for-flightdata', userOrgIds);
  const flags: FlightDataFlags = {
    insightsEnabled,
    preferSpiderSource,
    useNewFlightDataService
  };

  const { isFlightDataFetchingInProgress } = useSelector(
    (state: FullState) => state.flightExplorer,
    (prev, next) => prev.isFlightDataFetchingInProgress === next.isFlightDataFetchingInProgress // avoid unnecessary re-renders
  );

  useFlightDataService({ track, flags });

  useEffect(() => {
    if (tracksWithStatus && tracksWithStatus[String(track.id)] == 'Available') {
      dispatch(
        setPanelVisibility({
          panelVisibility: { ['3dfr']: PanelVisibility.VISIBLE }
        })
      );
    } else {
      dispatch(
        setPanelVisibility({
          panelVisibility: { ['3dfr']: PanelVisibility.DISABLED }
        })
      );
    }
  }, [tracksWithStatus, dispatch, track.id]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const tz = displayTimezone ? displayTimezone : timezone;

  const formatDateTimeFn = dateFormatter(`${dateFormat} HH:mm:ss`);
  const [localDepartedDateTime] = formatStandardDateStrings(
    formatDateTimeFn,
    fromTimestamp(track.departedTime),
    tz
  );

  const totalDistance = `${
    convertMetresTo(track.totalDistance, distanceUnit)[0]
  } ${getShortenedDistanceUnit(distanceUnit)}`;
  const totalDurationSeconds = Math.round((track.endTime - track.departedTime) / 1000);
  const totalTime = `${formatSecondsToHoursDecimal(totalDurationSeconds, 2)} hrs`;
  const departedTime = `${localDepartedDateTime}`;
  const active = getIsActive(track.points);
  const historyPageFilterConfig = useSelector(getHistoryFilterConfig);
  const aircraft = useSelector(getAircraftData)[track.aircraft.id];

  const aircraftProperties = {
    registration: track.aircraft ? track.aircraft.registration : '',
    active,
    type: aircraft ? aircraft.type : null,
    make: aircraft ? aircraft.make : null,
    model: aircraft ? aircraft.model : null
  };
  const displayPoints = (points && points[track.trackId]) || [];
  let className = 'flightExplorer';
  const showVerticalScrollbar = windowHeight < WINDOW_HEIGHT_VERTICAL_SCROLLBAR_THRESHOLD;
  if (showVerticalScrollbar) {
    className += ' v-scroll';
  }

  const panelVisibility = useSelector(getPanelVisibility);
  const timelineVisible = panelVisibility['flightEventsTimeline'] !== PanelVisibility.HIDDEN;
  const flightExplorerChartVisible =
    panelVisibility['flightReportSummaryChart'] !== PanelVisibility.HIDDEN;

  return (
    <div className={className}>
      <FlightDetailPanel
        trackId={track.trackId.toString()}
        trackStatus={tracksWithStatus && tracksWithStatus[String(track.id)]}
        bootCount={track.flightId}
        departedTime={departedTime}
        totalDistance={totalDistance}
        totalTime={totalTime}
        aircraftProperties={aircraftProperties}
        filterConfig={historyPageFilterConfig}
      />

      <div className="topContainer">
        <div className="content">
          {timelineVisible && (
            <EventsTimeline selectedTrack={track} timezone={tz} displayPoints={displayPoints} />
          )}

          <FlightTracklogViewer
            selectedTrack={track}
            displayPoints={displayPoints}
            className="shadow mapView"
            timezone={tz}
          />
        </div>
      </div>

      {flightExplorerChartVisible && (
        <FlightExplorerChart
          selectedTrack={track}
          timezone={tz}
          displayPoints={displayPoints}
          aircraftId={track.aircraft.id}
        />
      )}

      <ControlsPanel dataLoadComplete={!isFlightDataFetchingInProgress} />
    </div>
  );
};

export default FlightExplorerPage;
