import { useEffect, useState } from 'react';

const CHART_SCRIPT_SOURCE = 'https://code.highcharts.com/highcharts.js';
const NAVIGATOR_SCRIPT_SOURCE = 'https://code.highcharts.com/modules/navigator.js';
const ACCESIBILITY_SCRIPT_SOURCE = 'https://code.highcharts.com/modules/accessibility.js';
const EXPORT_SCRIPT_SOURCE = 'https://code.highcharts.com/modules/exporting.js';
const OFFLINE_EXPORT_SCRIPT_SOURCE = 'https://code.highcharts.com/modules/offline-exporting.js';
const EXPORT_DATA_SCRIPT_SOURCE = 'https://code.highcharts.com/modules/export-data.js';

// Hook
export const useChartScripts = (): string => {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<string>('idle');

  const addScript = (scriptId: string, scriptSrc: string) => {
    const script = document.createElement('script');
    script.setAttribute('id', scriptId);
    script.setAttribute('src', scriptSrc);
    script.setAttribute('data-status', 'loading');

    document.body.appendChild(script);

    return script;
  };

  useEffect(() => {
    let chartScript = document.querySelector(`script[src="${CHART_SCRIPT_SOURCE}"]`);
    let navigatorScript = document.querySelector(`script[src="${NAVIGATOR_SCRIPT_SOURCE}"]`);
    let accessibilityScript = document.querySelector(`script[src="${ACCESIBILITY_SCRIPT_SOURCE}"]`);
    let exportScript = document.querySelector(`script[src="${EXPORT_SCRIPT_SOURCE}"]`);
    let offlineExportScript = document.querySelector(
      `script[src="${OFFLINE_EXPORT_SCRIPT_SOURCE}"]`
    );
    let exportDataScript = document.querySelector(`script[src="${EXPORT_DATA_SCRIPT_SOURCE}"]`);

    const exportDataScriptLoaded = (event: Event) => {
      if (exportDataScript) {
        if (event.type === 'load') {
          exportDataScript.setAttribute('data-status', 'ready');
          setStatus('ready');
        } else {
          exportDataScript.setAttribute('data-status', 'error');
        }
      }
    };

    const offlineExportScriptLoaded = (event: Event) => {
      if (offlineExportScript) {
        if (event.type === 'load') {
          offlineExportScript.setAttribute('data-status', 'ready');
          exportDataScript = addScript('accessibility', EXPORT_DATA_SCRIPT_SOURCE);
          exportDataScript.addEventListener('load', exportDataScriptLoaded);
        } else {
          offlineExportScript.setAttribute('data-status', 'error');
        }
      }
    };

    const exportScriptLoaded = (event: Event) => {
      if (exportScript) {
        if (event.type === 'load') {
          exportScript.setAttribute('data-status', 'ready');
          offlineExportScript = addScript('accessibility', OFFLINE_EXPORT_SCRIPT_SOURCE);
          offlineExportScript.addEventListener('load', offlineExportScriptLoaded);
        } else {
          exportScript.setAttribute('data-status', 'error');
        }
      }
    };

    const accessibilityScriptLoaded = (event: Event) => {
      if (accessibilityScript) {
        if (event.type === 'load') {
          accessibilityScript.setAttribute('data-status', 'ready');
          exportScript = addScript('accessibility', EXPORT_SCRIPT_SOURCE);
          exportScript.addEventListener('load', exportScriptLoaded);
        } else {
          accessibilityScript.setAttribute('data-status', 'error');
        }
      }
    };

    const navigatorScriptLoaded = (event: Event) => {
      if (navigatorScript) {
        if (event.type === 'load') {
          navigatorScript.setAttribute('data-status', 'ready');
          accessibilityScript = addScript('accessibility', ACCESIBILITY_SCRIPT_SOURCE);
          accessibilityScript.addEventListener('load', accessibilityScriptLoaded);
        } else {
          navigatorScript.setAttribute('data-status', 'error');
        }
      }
    };

    const chartScriptLoaded = (event: Event) => {
      if (chartScript) {
        if (event.type === 'load') {
          chartScript.setAttribute('data-status', 'ready');
          navigatorScript = addScript('navigator', NAVIGATOR_SCRIPT_SOURCE);
          navigatorScript.addEventListener('load', navigatorScriptLoaded);
        } else {
          chartScript.setAttribute('data-status', 'error');
        }
      }
    };

    if (
      !chartScript &&
      !navigatorScript &&
      !accessibilityScript &&
      !exportScript &&
      !offlineExportScript &&
      !exportDataScript
    ) {
      chartScript = addScript('highCharts', CHART_SCRIPT_SOURCE);
      chartScript.addEventListener('load', chartScriptLoaded);
    } else {
      if (
        chartScript &&
        navigatorScript &&
        accessibilityScript &&
        exportScript &&
        offlineExportScript &&
        exportDataScript
      ) {
        const chartScriptLoadStatus = chartScript.getAttribute('data-status');
        const navigatorScriptLoadStatus = navigatorScript.getAttribute('data-status');
        const accessibilityScriptLoadStatus = accessibilityScript.getAttribute('data-status');
        const exportScriptStatus = accessibilityScript.getAttribute('data-status');
        const offlineExportScriptStatus = accessibilityScript.getAttribute('data-status');
        const exportDataScriptStatus = accessibilityScript.getAttribute('data-status');

        if (
          chartScriptLoadStatus === 'ready' &&
          navigatorScriptLoadStatus === 'ready' &&
          accessibilityScriptLoadStatus === 'ready' &&
          exportScriptStatus === 'ready' &&
          offlineExportScriptStatus === 'ready' &&
          exportDataScriptStatus === 'ready'
        ) {
          setStatus('ready');
        }
      }
    }
  }, []);

  return status;
};

export default useChartScripts;
