import { useEffect } from 'react';
import pRetry from 'p-retry';
import PQueue from 'p-queue';
import { PrivateTrackData } from '../../common/api/spidertracks-sdk/types/TrackData';
import { useDispatch, useSelector } from 'react-redux';
import { chunkTrack, fetchChunkData } from './chunks';
import {
  setIsFlightDataFetchingInProgress,
  setSelectedTrackFlightData
} from '../../redux/slice/flightExplorer';
import { Chunk, Chunks, CompleteFlightDataSamples, FlightDataFlags } from '../../types/FlightData';
import { FullState } from '../../store';
import { getDataSource } from '../../redux/selectors/flightExplorer';

interface FlightDataServiceProps {
  track: PrivateTrackData;
  flags: FlightDataFlags;
}

export const useFlightDataService = ({ track, flags }: FlightDataServiceProps) => {
  const dispatch = useDispatch();
  const dataSource = useSelector(getDataSource);

  const { isFlightDataFetchingInProgress } = useSelector(
    (state: FullState) => state.flightExplorer,
    (prev, next) => prev.isFlightDataFetchingInProgress === next.isFlightDataFetchingInProgress // avoid unnecessary re-renders
  );
  const { aircraft } = track;
  const orgId = aircraft.org ? aircraft.org.id : '';

  const fetchFlightData = async (chunkIndex: string, chunk: Chunk) => {
    const formatedChunk: Chunks = {};
    const chunkCompleteFlightData = await fetchChunkData(track.id, chunk, flags, orgId);

    formatedChunk[chunkIndex] = {
      fromTimestamp: chunk.fromTimestamp,
      toTimestamp: chunk.toTimestamp,
      data: chunkCompleteFlightData
    };

    return formatedChunk;
  };

  useEffect(() => {
    flags.dataSource = dataSource;
    const queue = new PQueue({ concurrency: 10 });

    const processTrack = async () => {
      // setting to true
      dispatch(
        setIsFlightDataFetchingInProgress({
          isFlightDataFetchingInProgress: true
        })
      );
      try {
        const chunks = chunkTrack(track);
        const fetch = Object.keys(chunks).map(chunkKey =>
          queue.add(() =>
            pRetry(async () => await fetchFlightData(chunkKey, chunks[chunkKey]), {
              retries: 3,
              onFailedAttempt: error => {
                console.log(
                  `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left.`
                );
              }
            })
          )
        );
        const allChunks = await Promise.all(fetch);
        const selectedTrackChunks = allChunks.reduce((acc, val) => ({ ...acc, ...val }));
        const selectedTrackFlightData: CompleteFlightDataSamples = {
          raw: [],
          smoothed: []
        };

        Object.values(selectedTrackChunks || {}).map((chunk: Chunk) => {
          selectedTrackFlightData.raw = [
            ...selectedTrackFlightData.raw,
            ...(chunk.data?.raw || [])
          ];
          selectedTrackFlightData.smoothed = [
            ...selectedTrackFlightData.smoothed,
            ...(chunk.data?.smoothed || [])
          ];
        });
        selectedTrackFlightData.smoothed = selectedTrackFlightData.smoothed.sort(
          (a, b) => a.sampleTimeEpochMillis - b.sampleTimeEpochMillis
        );
        selectedTrackFlightData.raw = selectedTrackFlightData.raw.sort(
          (a, b) => a.sampleTimeEpochMillis - b.sampleTimeEpochMillis
        );
        dispatch(
          setSelectedTrackFlightData({
            selectedTrackFlightData
          })
        );
      } catch (err) {
        if (err) {
          console.error(`Error processing track: ${err}`);
        } else {
          console.error(`Unknown error processing track`);
        }
      } finally {
        dispatch(
          setIsFlightDataFetchingInProgress({
            isFlightDataFetchingInProgress: false
          })
        );
      }
    };

    processTrack();

    return () => {
      queue.clear(); // Clear the queue if the component unmounts
    };
  }, [dispatch, track, dataSource]);

  return [isFlightDataFetchingInProgress] as const;
};
