import { computeQuaternionForOrientation } from './quaternion';
import { AircraftRenderProperties, FlightDataSample } from '../../../types/FlightData';
import {
  availabilityFromTimestamps,
  document,
  formatUnixEpoch,
  hexToRGB,
  rgba
} from './czmlHelpers';
import { PrivateTrackData } from '../../../common/api/spidertracks-sdk/types/TrackData';
import { Ahrs, Position } from '../../../common/api/spidertracks-sdk/types/FlightData';

export interface AdjustedData {
  adjPosition: Position[];
  adjAHRS: Ahrs[];
}

const getModelURL = (isFixedWing: boolean, aircraftModel: string | null) => {
  const models = new Map([['AW09', '/helicopter_AS350.gltf']]);
  if (aircraftModel && models.has(aircraftModel)) {
    return models.get(aircraftModel);
  }

  return isFixedWing ? '/Cessna_172.gltf' : '/helicopter_AS350.gltf';
};

function path(fillColor: rgba, outsideColor: rgba) {
  return {
    material: {
      polylineOutline: {
        color: {
          rgba: fillColor
        },
        outlineColor: {
          rgba: outsideColor
        },
        outlineWidth: 1
      }
    },
    width: 4,
    resolution: 1
  };
}

function model(aircraftType: string, aircraftModel?: string) {
  const isFixedWing = aircraftType.toLowerCase().startsWith('fixed');
  const modelURL = getModelURL(isFixedWing, aircraftModel || '');
  return {
    id: 'airplane',
    gltf: modelURL,
    scale: 1,
    minimumPixelSize: 28 * 2,
    nodeTransformations: {
      RootNode: {
        rotation: {
          unitQuaternion: [0, 0.7071, 0, 0.7071]
        }
      }
    }
  };
}

export async function czmlModelAndClock(
  selectedTrack: PrivateTrackData,
  aircraft: AircraftRenderProperties,
  markerColor: string
) {
  const color: rgba = aircraft.color ? hexToRGB(markerColor) : [255, 0, 255, 255];
  const insideColor: rgba = aircraft.color ? hexToRGB('BFBFBF', 204) : [255, 0, 255, 255];

  const { timestamp, latitude, longitude, altitude, altitudeUnit } = selectedTrack.points[0];
  let altMeter = altitude;
  if (altitudeUnit === 'ft') {
    altMeter = altitude * 0.3048;
  }

  const result = {
    positions: [formatUnixEpoch(timestamp / 1000), longitude, latitude, altMeter]
  };

  return [
    document(selectedTrack),
    {
      id: 'path',
      availability: availabilityFromTimestamps(
        selectedTrack.departedTime / 1000,
        selectedTrack.endTime / 1000
      ),
      path: path(insideColor, color),
      model: model(aircraft.type, aircraft.model),
      viewFrom: {
        cartesian: [70, 20, 30]
      },
      position: {
        interpolationAlgorithm: 'LAGRANGE',
        interpolationDegree: 1,
        cartographicDegrees: result.positions
      }
    }
  ];
}

export async function czmlPath(
  selectedTrack: PrivateTrackData,
  lockedToGroundData: FlightDataSample[],
  aircraft: AircraftRenderProperties,
  markerColor: string
) {
  const color: rgba = aircraft.color ? hexToRGB(markerColor) : [255, 0, 255, 255];
  const insideColor: rgba = aircraft.color ? hexToRGB('BFBFBF', 204) : [255, 0, 255, 255];

  const positions = new Array<string | number>(lockedToGroundData.length * 4);
  const orientations = new Array<string | number>(lockedToGroundData.length * 5);
  lockedToGroundData.forEach((sample, index) => {
    positions[index * 4] = formatUnixEpoch(sample.sampleTimeEpochMillis / 1000);
    positions[index * 4 + 1] = sample.longitude;
    positions[index * 4 + 2] = sample.latitude;
    positions[index * 4 + 3] = sample.altitudeMetres;
    const { x, y, z, w } = computeQuaternionForOrientation(
      sample.longitude,
      sample.latitude,
      sample.altitudeMetres,
      sample.yawRadians,
      sample.rollRadians * -1,
      sample.pitchRadians
    );
    orientations[index * 5] = formatUnixEpoch(sample.sampleTimeEpochMillis / 1000);
    orientations[index * 5 + 1] = x;
    orientations[index * 5 + 2] = y;
    orientations[index * 5 + 3] = z;
    orientations[index * 5 + 4] = w;
  });

  return [
    document(undefined),
    {
      id: 'path',
      position: {
        interpolationAlgorithm: 'LAGRANGE',
        interpolationDegree: 1,
        cartographicDegrees: positions
      },
      orientation: {
        unitQuaternion: orientations
      }
    }
  ];
}
