import React, { useEffect, useState } from 'react';
import { Button, Spin, PageHeader, Table, Modal } from 'antd';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getUserData } from '../../../redux/selectors/userData';
import { initializeInstance, getInstance } from '../../../common/api/spidertracks-sdk';
import { ConnectToAirmaestroDrawer } from './AirMaestro/ConnectToAirmaestroDrawer';
import { useFeatureFlag } from '../../../hooks';

const ManageApps = () => {
  const { organisationId } = useParams();
  const userData = useSelector(getUserData);
  const organisationName =
    userData && userData.orgs
      ? userData.orgs.find(org => org.org.id === organisationId).org.orgName
      : 'unknown';

  const isAirMaestroEnabled = useFeatureFlag('air-maestro-integration', [organisationId]);

  // fixed for all users
  const [flightLoggerAppClientId, setFlightLoggerAppClientId] = useState('');
  const [amAppClientId, setAMAppClientId] = useState('');

  // loading state related
  const [flightLoggerLoading, setFlightLoggerLoading] = useState(false);
  const [amConnectionLoading, setAMConnectionLoading] = useState(false);
  const [flightloggerConnection, setFlightloggerConnection] = useState(undefined);
  const [amConnection, setAMConnection] = useState(undefined);

  // view state, changes with user interaction
  const [connectToAMDrawerVisibility, setConnectToAMDrawerVisibility] = useState(false);

  const connectToFlightLogger = async () => {
    setFlightLoggerLoading(true);
    const response = await getInstance()
      .getIntegrationService()
      .initialiseConnection(organisationName, organisationId, flightLoggerAppClientId);
    setFlightLoggerLoading(false);

    window.location.href = response.redirectUrl;
  };

  const connectToAirMaestro = async thirdPartyUrl => {
    const response = await getInstance()
      .getIntegrationService()
      .initialiseConnection(organisationName, organisationId, amAppClientId, thirdPartyUrl);

    window.location.href = response.redirectUrl;
  };

  const disconnectFromFlightLogger = async () => {
    Modal.confirm({
      content: `Are you sure you want to disconnect the FlightLogger integration?`,
      onOk: async () => {
        if (flightloggerConnection == undefined) {
          return;
        }
        await getInstance()
          .getIntegrationService()
          .resetConnection(flightloggerConnection.id);
        setFlightloggerConnection(undefined);
      }
    });
  };

  const disconnectFromAirMaestro = async () => {
    Modal.confirm({
      content: `Are you sure you want to disconnect the AirMaestro integration?`,
      onOk: async () => {
        if (amConnection == undefined) {
          return;
        }
        await getInstance()
          .getIntegrationService()
          .resetConnection(amConnection.id);
        setAMConnection(undefined);
      }
    });
  };

  const onConnectToAMDrawerClose = () => {
    setConnectToAMDrawerVisibility(false);
  };

  useEffect(() => {
    const getConfig = async () => {
      const config = await fetch('/config.json').then(config => config.json());
      window.env = config;
      setFlightLoggerAppClientId(config.flightlogger.clientId);
      setAMAppClientId(config.airmaestro.clientId);

      try {
        initializeInstance({
          baseUrl: config.api.endpoint,
          isPublic: false
        });
      } catch (err) {
        console.error(err);
      }
    };
    getConfig();
  }, []);

  useEffect(() => {
    const getConnectionStatus = async () => {
      if (!amAppClientId && !flightLoggerAppClientId) {
        setFlightLoggerLoading(false);
        return;
      }

      setFlightLoggerLoading(true);
      setAMConnectionLoading(true);
      const response = await getInstance()
        .getIntegrationService()
        .getIntegrationStatus(organisationId);

      const connections = response.results;

      if (amAppClientId) {
        let connection = connections.find(el => {
          return el.status == 'CONNECTED' && el.appClientId == amAppClientId;
        });
        setAMConnectionLoading(false);
        if (connection != undefined) {
          setAMConnection(connection);
        } else {
          setAMConnection(undefined);
        }
      }

      if (flightLoggerAppClientId) {
        let connection = connections.find(el => {
          return el.status == 'CONNECTED' && el.appClientId == flightLoggerAppClientId;
        });

        setFlightLoggerLoading(false);
        if (connection != undefined) {
          setFlightloggerConnection(connection);
        } else {
          setFlightloggerConnection(undefined);
        }
      }
    };
    getConnectionStatus();
  }, [amAppClientId, flightLoggerAppClientId]);

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        return <img src={record.icon} alt="icon" style={{ width: '30px' }} />;
      }
    },
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Status',
      width: '15%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: function(_, record) {
        if (record.connectionLoading) {
          return <Spin></Spin>;
        }
        if (record.connection != undefined) {
          return <span>Connected</span>;
        }
        return <span>Disconnected</span>;
      }
    },
    {
      title: 'Action',
      width: '10%',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: function(_, record) {
        if (record.connectionLoading) {
          return <></>;
        }
        if (record.connection != undefined) {
          return (
            <Button type="danger" onClick={record.disconnector}>
              Disconnect
            </Button>
          );
        }
        return (
          <Button type="primary" onClick={record.connector}>
            Connect
          </Button>
        );
      }
    }
  ];

  const rowData = [
    {
      key: 'flightlogger',
      icon: '/partners/flight-logger.png',
      name: 'FlightLogger',
      connector: connectToFlightLogger,
      disconnector: disconnectFromFlightLogger,
      connection: flightloggerConnection,
      connectionLoading: flightLoggerLoading
    }
  ];
  if (isAirMaestroEnabled) {
    rowData.push({
      key: 'airmaestro',
      icon: 'https://airmaestro.net/assets/Icons/air-maestro-logo-icon.svg',
      name: 'Air Maestro',
      connector: () => {
        setConnectToAMDrawerVisibility(true);
      },
      disconnector: disconnectFromAirMaestro,
      connection: amConnection,
      connectionLoading: amConnectionLoading
    });
  }

  return (
    <React.Fragment>
      <div className="px-5 py-4">
        <PageHeader
          className="site-page-header"
          style={{ paddingLeft: '0px' }}
          title="Manage Apps"
        />
        <Table
          bordered
          dataSource={rowData}
          columns={columns}
          pagination={false}
          showHeader={false}
        ></Table>
        {connectToAMDrawerVisibility && (
          <ConnectToAirmaestroDrawer
            visibility={connectToAMDrawerVisibility}
            onClose={onConnectToAMDrawerClose}
            connectToAm={connectToAirMaestro}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ManageApps;
