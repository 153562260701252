import React from 'react';
import { AircraftProperties } from '.';

export const AircraftPropertiesTooltipContent: React.FC<AircraftProperties> = (
  aircraftProperties: AircraftProperties
) => {
  const getAircraftType = (type: string | null) => {
    if (type === null) {
      return '[empty]';
    }

    switch (type) {
      case 'FIXED_WING':
        return 'Fixed Wing';
      case 'ROTARY_WING':
        return 'Rotary Wing';
      default:
        return '[unknown]';
    }
  };

  return (
    <div>
      <span>
        Status: {aircraftProperties.active ? 'Active' : 'Inactive'}
        <br />
      </span>
      <span>
        Type: {getAircraftType(aircraftProperties.type)}
        <br />
      </span>
      <span>
        Make: {aircraftProperties.make ? aircraftProperties.make : '[empty]'}
        <br />
      </span>
      <span>
        Model: {aircraftProperties.model ? aircraftProperties.model : '[empty]'}
        <br />
      </span>
    </div>
  );
};
