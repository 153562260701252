import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  AircraftContainer,
  AircraftWrapper,
  DurationWrapper,
  AircraftNameWrapper,
  TimeWrapper,
  Status,
  Row,
  StyledSmsIcon
} from './styles/js/styled';
import {
  dateFormatter,
  formatStandardDateStrings,
  fromTimestamp
} from '../../../../../helpers/formatTime';
import { getDateFormat, getTimezone } from '../../../../../redux/selectors/userData';
import { Tooltip } from 'antd';

const SpidertxtIcon = ({ userDisplayName }) => {
  return userDisplayName ? (
    <Tooltip placement="right" title={userDisplayName} trigger="hover">
      <StyledSmsIcon data-testid="spidertxt-icon" />
    </Tooltip>
  ) : (
    <StyledSmsIcon data-testid="spidertxt-icon" />
  );
};

SpidertxtIcon.propTypes = {
  userDisplayName: PropTypes.string
};

const AircraftRow = ({ aircraft }) => {
  const dateFormat = useSelector(getDateFormat);
  const timezone = useSelector(getTimezone);
  const localMoment = fromTimestamp(aircraft.departedTime);

  const activeDeviceConnection = aircraft.mostRecentTrack?.aircraft?.activeDeviceConnection;

  const formatFn = dateFormatter(dateFormat);
  const [localTime] = formatStandardDateStrings(formatFn, localMoment, timezone);
  return (
    <AircraftContainer>
      <Status status={aircraft.trackStatus} />
      <Row>
        <AircraftNameWrapper>{aircraft.registration}</AircraftNameWrapper>
        {activeDeviceConnection && aircraft.isActive && (
          <SpidertxtIcon userDisplayName={activeDeviceConnection.user?.displayName} />
        )}
      </Row>
      <AircraftWrapper>
        <TimeWrapper>{localTime}</TimeWrapper>
        <DurationWrapper>{aircraft.flightDuration}</DurationWrapper>
      </AircraftWrapper>
    </AircraftContainer>
  );
};

AircraftRow.defaultProps = {
  data: ''
};

AircraftRow.propTypes = {
  aircraft: PropTypes.object
};

export default AircraftRow;
