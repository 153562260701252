import { useState, useEffect } from 'react';
import { getInstance } from '../common/api/spidertracks-sdk';
import { flightReplayStatusError } from '../helpers';
import { Track } from '../types/Track';
import { FlightStatus } from '../constants';
import {SDK} from "../common/api/spidertracks-sdk/SDK";
import {PrivateTrackData} from "../common/api/spidertracks-sdk/types/TrackData";

export interface Status {
  [id: string]: FlightStatus;
}

export interface FlightDataFlags {
  useNewFlightDataService: boolean;
  preferSpiderSource: boolean;
  insightsEnabled: boolean;
}

async function flightDataServiceStatus(SpidertracksSDK:SDK, tracks: Track[] | PrivateTrackData[], flags:FlightDataFlags | undefined): Promise<Status> {
  const status = await SpidertracksSDK.getFlightDataService().getFlightStatus(
    tracks.map((t: Track | PrivateTrackData) => t.id)
  );
  return status.items.reduce((statusObj, t) => {
    return {
      ...statusObj,
      [t.id]: t.status
    };
  }, {});
}

async function use3dFRFlightDataStatus(SpidertracksSDK: SDK, tracks: Track[] | PrivateTrackData[], flags: FlightDataFlags | undefined): Promise<Status> {
  interface TrackWithId {
    id:string|number;
    trackId:string|number;
  };
  const tracksWithTrackIdTrackWithTrackId:TrackWithId[] = tracks;
  if (!flags) {
    return tracksWithTrackIdTrackWithTrackId.reduce<Status>((statusObj, t: TrackWithId) => {
      return {
        ...statusObj,
        [t.id]: FlightStatus.NOT_AVAILABLE,
      };
    }, {} as Status);
  }
  /*vvvvvvvv DEVELOPMENT TOOLING vvvvvvvv*/
  const sourcesFromQuery = new URLSearchParams(window.location.search).get('3dfrsources');
  let sources = 'platform,spider';
  if (flags.preferSpiderSource) {
    sources = 'spider,platform';
  }

  //We don't want to allow insights customers to use the spider source for now.
  if (flags.insightsEnabled === true) {
    sources = 'platform';
  }

  if (sourcesFromQuery !== null) {
    sources = sourcesFromQuery;
  }
  /*^^^^^^^^ DEVELOPMENT TOOLING ^^^^^*/
  const status = await SpidertracksSDK.getFlightData3DFRService().getFlightDataStatus(
    tracks.map((t: Track | PrivateTrackData) => t.id),
    sources
  );
  const statusToFlightStatus = {
    notAvailable: FlightStatus.NOT_AVAILABLE,
    available: FlightStatus.AVAILABLE,
    inProgress: FlightStatus.INCOMPLETE,
    notSupported: FlightStatus.NOT_SUPPORTED,
    archived: FlightStatus.ARCHIVED
  }
  return status.reduce((statusObj, t) => {
    return {
      ...statusObj,
      [t.trackId]: statusToFlightStatus[t.status],
    };
  }, {});
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFlightStatus = (tracks: Track[] | PrivateTrackData[], flags:FlightDataFlags | undefined, silentErrors:boolean = false ): [Status | null, boolean, null | Error] => {
  const SpidertracksSDK = getInstance();

  const [flightStatus, setFlightStatus] = useState<Status | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trackJSON, setTrackJSON] = useState<string>("");
  const statusFn = flags ? (flags.useNewFlightDataService ? use3dFRFlightDataStatus : flightDataServiceStatus) : flightDataServiceStatus

  useEffect(() => {
    const init = async () => {
      if (tracks.length !== 0) {
        try {
          setLoading(true);
          const status = await statusFn(SpidertracksSDK, tracks, flags);
          setFlightStatus(status);
          setLoading(false);
        } catch (error) {
          if (!silentErrors) {
            console.log("Not silent error")
            flightReplayStatusError();
          }
          setLoading(false);
          setError(() => error);
          console.log(error);
        }
      }
    }
    if (trackJSON.length > 0) {
      init();
    }
  }, [trackJSON]);

  useEffect(() => {
    setTrackJSON(JSON.stringify(tracks));
  }, [tracks]);

  return [flightStatus, loading, error];
};
