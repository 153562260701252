import React from 'react';
import { Button, Input, Dropdown, Icon, Divider } from 'antd';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { FilterResults, FilterOptions } from '../../common/filters';
import { KeyIndex } from '../../../types/KeyIndex';

export const AircraftFilter = ({
  filters,
  selectedAircraft,
  selectAircraft,
  clearAircraftFilter,
  confirmFilter,
  visible,
  setVisible,
  searchText,
  setSearchText,
  disabled
}: {
  filters: KeyIndex[];
  selectedAircraft: string[];
  selectAircraft: (checkedValue: CheckboxValueType[]) => void;
  clearAircraftFilter: Function;
  confirmFilter: Function;
  visible: boolean;
  setVisible: Function;
  searchText: string;
  setSearchText: Function;
  disabled?: boolean;
}) => {
  const updateInputField = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setSearchText(e.target.value ? e.target.value : '');

  const overlay = (
    <div
      style={{
        padding: 8,
        backgroundColor: 'white',
        borderRadius: '4px',
        outline: 'none',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
      }}
      id="insights-aircraft-filter"
      className="checkbox-hide-disabled"
    >
      <Input
        placeholder={`Search`}
        value={searchText}
        onChange={updateInputField}
        style={{ width: '100%', marginBottom: 8, display: 'block' }}
      />
      <CheckboxGroup
        options={FilterResults({ searchText, filters, filterKey: 'value' })}
        value={selectedAircraft}
        onChange={selectAircraft}
        style={{
          maxHeight: '250px',
          width: '14rem',
          overflow: 'hidden',
          overflowY: 'scroll',
          display: 'grid'
        }}
      />
      <Divider style={{ margin: '0px', padding: '0px' }} />
      <FilterOptions confirmFilter={confirmFilter} resetFilter={clearAircraftFilter} />
    </div>
  );

  return (
    <Dropdown
      visible={visible}
      onVisibleChange={flag => setVisible(flag)}
      overlay={overlay}
      trigger={['click']}
      disabled={disabled}
    >
      <Button
        className={
          !selectedAircraft.length
            ? 'ant-dropdown-link'
            : 'ant-btn-primary ant-btn-background-ghost'
        }
        style={{
          position: 'sticky',
          marginLeft: '1.3rem',
          width: '10rem',
          height: '40px'
        }}
        onClick={e => e.preventDefault()}
      >
        Aircraft <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

AircraftFilter.displayName = 'AircraftFilter';
