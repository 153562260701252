import { FlightDataFlags, FlightDataSample } from '../../types/FlightData';
import { getInstance } from '../../common/api/spidertracks-sdk';

export interface CompleteFlightDataServiceResponse {
  raw: FlightDataSample[];
  smoothed: FlightDataSample[];
}

export async function getFlightData(
  flags: FlightDataFlags,
  trackId: number,
  orgId: string,
  from?: number,
  to?: number,
  ahrsStart?: string
): Promise<CompleteFlightDataServiceResponse> {
  const FLIGHT_DATA_3DFR_SERVICE_INSTANCE = getInstance().getFlightData3DFRService();
  //Extract the source from the URL for development purposes
  /*vvvvvvvv DEVELOPMENT TOOLING vvvvvvvv*/
  const sourcesFromQuery = new URLSearchParams(window.location.search).get('3dfrsources');
  let sources = 'platform,spider';

  if (flags.preferSpiderSource) {
    sources = 'spider,platform';
  }

  //We don't want to allow insights customers to use the spider source for now.
  if (flags.insightsEnabled === true) {
    sources = 'platform';
  }

  if (sourcesFromQuery !== null) {
    sources = sourcesFromQuery;
  }
  if (flags.dataSource && flags.dataSource != '') {
    sources = flags.dataSource;
  }
  /*^^^^^^^^ DEVELOPMENT TOOLING  ^^^^^^*/

  const fromTime = ahrsStart ? parseInt(ahrsStart) : from;
  const toTime = to;
  let r: CompleteFlightDataServiceResponse = await FLIGHT_DATA_3DFR_SERVICE_INSTANCE.getCompleteFlightData(
    trackId,
    orgId,
    sources,
    fromTime,
    toTime
  );

  return r;
}
