import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Button, Spin, Tooltip } from 'antd';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import AirmaestroFormInput from './AirmaestroFormInput';

export const AirmaestroForm = ({
  form,
  onClose,
  connect,
  submitButtonValue,
  isSubmitButtonDisabled,
  handleFieldChange
}) => {
  const { getFieldDecorator, validateFields, setFieldsValue } = form;
  const [isConnecting, setIsConnecting] = useState(false);

  const connectToAm = async e => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      setIsConnecting(true);
      if (!values.thirdPartyUrl.includes('localhost')) {
        const finalThirdPartyUrl = `https://${values.thirdPartyUrl}.airmaestro.com.au/Spidertracks.ashx`;
        await connect(finalThirdPartyUrl);
        return;
      }

      const finalThirdPartyUrl = `https://${values.thirdPartyUrl}/Spidertracks.ashx`;
      const useLocalhost = confirm(
        `"localhost" is a special site address used for development. Are you sure you want to connect to ${finalThirdPartyUrl}?`
      );
      if (!useLocalhost) {
        setIsConnecting(false);
        return;
      }
      await connect(finalThirdPartyUrl);
    });
  };

  const subdomainInputLabel = (
    <span>
      Site Address(sub-domain)
      <Tooltip
        placement="right"
        title={
          <span>
            The sub-domain is the first part of your Air Maestro URL. For example, if your URL is
            test.airmaestro.com.au, the subdomain would be test. Special characters such as @,#,$
            are not allowed.
          </span>
        }
      >
        <ExclamationCircleFilled className="ml-2" style={{ color: 'rgba(0,0,0,0.25)' }} />
      </Tooltip>
    </span>
  );

  return (
    <Form
      onSubmit={connectToAm}
      layout="vertical"
      hideRequiredMark={false}
      style={{ width: '308px', margin: '30px 0 0 32px' }}
    >
      <AirmaestroFormInput
        name="thirdPartyUrl"
        label={subdomainInputLabel}
        getFieldDecorator={getFieldDecorator}
        validationRules={[
          {
            required: true,
            /** A regular subdomain, or localhost with an optional port */
            pattern: '^^([A-Za-z0-9](?:[A-Za-z0-9-.]{0,61}[A-Za-z0-9])?|(localhost(:[0-9]+)?))$',
            message: 'Sub-domain must be of correct format'
          }
        ]}
        placeholder="ex: subdomain"
        handleFieldChange={handleFieldChange}
      />

      <Form.Item style={{ textAlign: 'right' }} className="mt-3">
        <Row>
          <Button
            name="cancel"
            className="mx-2 px-3"
            type="secondary"
            size="large"
            onClick={onClose}
            style={{ width: 'auto' }}
          >
            Cancel
          </Button>
          <Button
            name="connect"
            className="mx-2 px-4"
            disabled={isConnecting || isSubmitButtonDisabled}
            type="primary"
            htmlType="submit"
            size="large"
            style={{ width: 'auto' }}
          >
            {isConnecting ? (
              <span className="px-2">
                <Spin size="small" />
              </span>
            ) : (
              submitButtonValue
            )}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

AirmaestroForm.propTypes = {
  form: PropTypes.object,
  onClose: PropTypes.func,
  connect: PropTypes.func.isRequired,
  submitButtonDefaultName: PropTypes.string,
  submitButtonValue: PropTypes.string.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  handleFieldChange: PropTypes.func.isRequired
};

export default Form.create()(AirmaestroForm);
