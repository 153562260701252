import Flight3DPlayer from '@spidertracks/flight-3d-player';
import { helpers } from '@spidertracks/flight-3d-player';
import { getInstance } from 'common/api/spidertracks-sdk';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCZML } from './getCZML';
import { getCzmlParts } from './getCzmlParts';
import { LoadingBackground } from '../LoadingBackground';
import { replayError } from '../../helpers';
import { useLocalStorage } from '../../hooks';

const MAX_POINTS = 500;

/**
 * Checks if the API response provided is not sufficient to build the part
 * @param {Object} part FlightDataPart
 * @param {Object} response FlightData API response
 */
const responseNotCompletePart = (part, response) =>
  part.to > response.to &&
  !!response.nextToken &&
  (!!response.nextToken.ahrs || !!response.nextToken.position);

const Flight3dPlayerContainer = ({ trackId, stId, aircraftType, aircraftModel, orgId, flags }) => {
  const FLIGHT_DATA_SERVICE_INSTANCE = getInstance().getFlightDataService();
  const FLIGHT_DATA_3DFR_SERVICE_INSTANCE = getInstance().getFlightData3DFRService();

  const [terrainProvider, setTerrainProvider] = useState();
  const [czmlParts, setCzmlParts] = useState();
  const [flightDataSource, setFlightDataSource] = useState();
  const [flightDataSmoothened, setFlightDataSmoothened] = useState();
  const [iAmDeveloper] = useLocalStorage('iAmDeveloper', false);

  const getFlightData = async (trackId, stId, from, to, maxPoints, ahrsStart, positionStart) => {
    let response;
    if (flags.useNewFlightDataService === true) {
      // Extract the source from the URL for development purposes
      const sourcesFromQuery = new URLSearchParams(window.location.search).get('3dfrsources');
      let sources = 'platform,spider';

      if (flags.preferSpiderSource) {
        sources = 'spider,platform';
      }

      // We don't want to allow insights customers to use the spider source for now.
      if (flags.insightsEnabled === true) {
        sources = 'platform';
      }

      if (sourcesFromQuery !== null) {
        sources = sourcesFromQuery;
      }

      const fromTime = ahrsStart || from;
      const toTime = to;
      response = await FLIGHT_DATA_3DFR_SERVICE_INSTANCE.getFlightData(
        trackId,
        orgId,
        sources,
        fromTime * 1000,
        toTime * 1000
      );
      setFlightDataSource(response.source);
      setFlightDataSmoothened(response.smoothing);
    } else {
      response = await FLIGHT_DATA_SERVICE_INSTANCE.get(
        trackId,
        stId,
        from,
        to,
        maxPoints,
        ahrsStart,
        positionStart
      );
    }

    return response;
  };

  const buildCZMLPartFromResponse = async (response, part, isFirstPart = false) => {
    let current = response;
    while (responseNotCompletePart(part, current)) {
      // We didn't get all the data for the part in the response provided, so need to keep calling the api
      current = await getFlightData(
        trackId,
        stId,
        part.from,
        part.to,
        MAX_POINTS,
        current.nextToken.ahrs,
        current.nextToken.position
      );

      response.ahrs = [...response.ahrs, ...current.ahrs]; //FIXME: realloc
      response.position = [...response.position, ...current.position]; //FIXME: realloc
    }

    return getCZML(response, aircraftType, aircraftModel, terrainProvider, isFirstPart);
  };

  const requestCzmlPart = async partIndex => {
    const part = czmlParts[partIndex];

    try {
      const flightData = await getFlightData(trackId, stId, part.from, part.to, MAX_POINTS);
      const czml = await buildCZMLPartFromResponse(flightData, part);

      setCzmlParts({
        ...czmlParts, //FIXME: realloc
        [partIndex]: {
          ...part, //FIXME: realloc
          czml
        }
      });
    } catch (err) {
      console.error(err);
      replayError();
    }
  };

  useEffect(() => {
    setTerrainProvider(helpers.getTerrainProvider());
  }, []);

  useEffect(() => {
    async function init() {
      if (!terrainProvider) {
        return;
      }
      try {
        let flightData = await getFlightData(trackId, stId, undefined, undefined, 10);
        if (!czmlParts) {
          const parts = getCzmlParts(flightData);
          const firstPart = Object.values(parts)[0];
          firstPart.czml = await buildCZMLPartFromResponse(flightData, firstPart, true);
          setCzmlParts(parts);
        }
      } catch (e) {
        console.error(e);
        replayError();
      }
    }
    init();
  }, [terrainProvider]);
  return terrainProvider && czmlParts ? (
    <>
      <Flight3DPlayer
        czmlParts={czmlParts}
        requestCzmlPart={requestCzmlPart}
        terrainProvider={terrainProvider}
      />
      {(iAmDeveloper || flags.useNewFlightDataService) && (
        <span style={{ backgroundColor: 'white', padding: '2px' }}>
          Source: {flightDataSource} , Smoothing:
          {flightDataSmoothened === undefined ? 'false' : flightDataSmoothened.toString()}
        </span>
      )}
    </>
  ) : (
    <LoadingBackground />
  );
};

Flight3dPlayerContainer.propTypes = {
  trackId: PropTypes.string.isRequired,
  stId: PropTypes.string.isRequired,
  aircraftType: PropTypes.string.isRequired,
  aircraftModel: PropTypes.string,
  orgId: PropTypes.string.isRequired,
  flags: PropTypes.shape({
    useNewFlightDataService: PropTypes.bool,
    preferSpiderSource: PropTypes.bool,
    insights: PropTypes.bool
  })
};

export default Flight3dPlayerContainer;
