import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Collapse, Spin } from 'antd';
import RCNotification from 'rc-notification';
import { connect, Provider } from 'react-redux';
import store from 'store';
import { push } from 'connected-react-router';
import { getContactDetails } from '../../redux/reducers/navigationReducer/actions/thunk';
import { closeSos, escalateSos } from '../../common/api/sosNotificationApi';
import {
  setSelectedTrackId,
  setSelectedTracks as setSelectedTracksAction
} from '../../redux/slice/tracks';
import { selectedMarkerPoint as selectedMarkerPointAction } from '../../redux/reducers/mapReducer/actions/map';
import {
  BodyContent,
  BodyWrapper,
  buttonStyle,
  ButtonWrapper,
  popupStyles,
  SosHeader,
  Time
} from './styled';
import { dateFormatter, formatStandardDateStrings, fromTimestamp } from '../../helpers/formatTime';
import { getDateTimeFormat, getTimezone } from '../../redux/selectors/userData';
import { getTrackById } from '../../redux/selectors/aircraftData';
import 'rc-notification/assets/index.css';
import './styles.scss';
import { playTune, Tune } from '../../helpers/audio';

const { Panel } = Collapse;

let notification = null;
const notificationsArray = [];
RCNotification.newInstance({}, n => {
  notification = n;
});

const closeNotification = e => {
  const key = Number(e.currentTarget.getAttribute('data-key'));
  notification.removeNotice(key);
};

export class SosComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSosDetails: true,
      showingCloseOption: false,
      showingEscalateOption: false,
      contactDetails: {},
      escalated: this.props.isTierTwo
    };
    this.getContacts = this.getContacts.bind(this);
    this.viewAircraft = this.viewAircraft.bind(this);
    this.renderSosContent = this.renderSosContent.bind(this);
  }

  getContacts() {
    const { sosData } = this.props;

    getContactDetails(sosData.trackIdentifier).then(response => {
      const { items } = response;
      // eslint-disable-next-line
      const points = items[0].points;
      // eslint-disable-next-line
      const contacts = items[0].sos.contacts;

      this.setState({
        contactDetails: {
          coordinates: points[points.length - 1].coordinates,
          altitude: points[points.length - 1].altitude,
          altitudeUnit: points[points.length - 1].altitudeUnit,
          speed: points[points.length - 1].speed,
          speedUnit: points[points.length - 1].speedUnit,
          direction: points[points.length - 1].heading,
          tier: items[0].sos.tier,
          contacts
        }
      });
    });
  }

  closeSosAlert = id => {
    notification.removeNotice(id);
    closeSos(id);
  };

  escalateSosAlert = id => {
    this.setState({
      escalated: true
    });
    escalateSos(id).then(() => {
      this.getContacts();
    });
    this.cancelEscalateOption();
  };

  viewAircraft = () => {
    const {
      sosTrack,
      sosData,
      setSelectedTracks,
      setSelectedTrackId,
      selectedMarkerPoint,
      navigateToRoute
    } = this.props;
    navigateToRoute('/');
    setSelectedTracks([sosData.trackIdentifier]);
    setSelectedTrackId(sosData.trackIdentifier);
    if (sosTrack) {
      selectedMarkerPoint(sosTrack.points?.slice(-1)[0]);
    }
  };

  showCloseOption = () => {
    this.setState({
      showingCloseOption: true,
      showSosDetails: false
    });
  };

  cancelCloseOption = () => {
    this.setState({
      showingCloseOption: false,
      showSosDetails: true
    });
  };

  showEscalateOption = () => {
    this.setState({
      showingEscalateOption: true,
      showSosDetails: false
    });
  };

  cancelEscalateOption = () => {
    this.setState({
      showingEscalateOption: false,
      showSosDetails: true
    });
  };

  renderContacts = () => {
    const { contactDetails } = this.state;
    const { contacts } = contactDetails;

    return contacts.map(contact => (
      <div key={contact.displayName}>
        {contact.displayName}
        <br />
      </div>
    ));
  };

  renderSosContent(sosData) {
    const {
      contactDetails,
      showingCloseOption,
      showSosDetails,
      showingEscalateOption,
      escalated
    } = this.state;
    const { dateFormat, timezone } = this.props;
    const { contacts } = contactDetails;
    let tierValue = sosData.tier;
    const tier1Data = [];
    const tier2Data = [];
    switch (sosData.tier) {
      case 'ONE':
        tierValue = 1;
        break;
      case 'TWO':
        tierValue = 2;
        break;
      default:
        tierValue = sosData.tier;
    }

    if (escalated && contactDetails.tier) {
      switch (contactDetails.tier) {
        case 'ONE':
          tierValue = 1;
          break;
        case 'TWO':
          tierValue = 2;
          break;
        default:
          tierValue = contactDetails.tier;
      }
    }

    const allContacts = contactDetails.contacts;
    if (allContacts) {
      allContacts.forEach(contact => {
        if (contact.tier !== null) {
          switch (contact.tier) {
            case 'ONE':
              tier1Data.push(contact);
              break;
            case 'TWO':
              tier2Data.push(contact);
              break;
            default:
              tier1Data.push(contact);
              tier2Data.push(contact);
          }
        }
      });
    }

    const localMoment = fromTimestamp(sosData.createdDate);

    const formatFn = dateFormatter(dateFormat);
    const [localTime, utcTime] = formatStandardDateStrings(formatFn, localMoment, timezone);

    return (
      <React.Fragment key={sosData.id}>
        <div className="notification-wrapper" id={sosData.id + 'wrapper'}>
          <SosHeader data-key={sosData.id + 'sosHeader'} callback={closeNotification} />
          <BodyWrapper>
            {showSosDetails && (
              <div>
                <BodyContent>
                  SOS: Tier
                  {tierValue}
                  <br />
                  Aircraft: {sosData.aircraft.registration}
                  <br />
                  Status: {sosData.open ? 'Open' : 'Close'}{' '}
                  <Collapse onChange={this.getContacts} accordion bordered={false}>
                    <Panel header="Show more" key="1">
                      {Object.keys(contactDetails).length === 0 && <Spin />}
                      {Object.keys(contactDetails).length > 0 && (
                        <div>
                          {!contacts && <Spin />}
                          {contacts && (
                            <React.Fragment>
                              <div
                                style={{
                                  display: 'grid',
                                  gridTemplateColumns: '0.5fr 1fr'
                                }}
                                className="sos-point-info"
                              >
                                <div>
                                  Location:
                                  <br />
                                  <br />
                                  Altitude:
                                  <br />
                                  Speed:
                                  <br />
                                  Direction:
                                  <br />
                                  Issued:
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </div>
                                <div>
                                  {contactDetails.coordinates.value[0]}
                                  <br />
                                  {contactDetails.coordinates.value[1]}
                                  <br />
                                  {contactDetails.altitude} {contactDetails.altitudeUnit}
                                  <br />
                                  {contactDetails.speed} {contactDetails.speedUnit}
                                  <br />
                                  {contactDetails.direction}
                                  &deg;T
                                  <br />
                                  {localTime}
                                  <br />
                                  {utcTime}
                                  <br />
                                </div>
                              </div>
                              <div>
                                <div
                                  style={{
                                    margin: '10px 0 10px 0',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Contacted people
                                </div>

                                <div
                                  style={{
                                    marginBottom: '15px'
                                  }}
                                >
                                  Tier 1
                                  {tier1Data.map((object, index) => (
                                    <div className="tierName" key={object.contactedDate}>
                                      {tier1Data[index].firstName} {tier1Data[index].lastName}
                                    </div>
                                  ))}
                                </div>

                                {tier2Data.length > 0 && (
                                  <div
                                    style={{
                                      marginBottom: '15px'
                                    }}
                                  >
                                    Tier 2
                                    {tier2Data.map((object, index) => (
                                      <div className="tierName" key={object.contactedDate}>
                                        {tier2Data[index].firstName} {tier2Data[index].lastName}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      )}
                    </Panel>
                  </Collapse>
                  {
                    <Time>
                      <span className="timestamp">
                        {moment(sosData.createdDate).format('MM/DD HH:mm')}
                      </span>
                    </Time>
                  }
                </BodyContent>

                <ButtonWrapper>
                  <Button style={buttonStyle} onClick={this.showCloseOption}>
                    Close
                  </Button>
                  <Button onClick={this.viewAircraft} style={buttonStyle}>
                    View
                  </Button>
                  <Button
                    type="primary"
                    disabled={sosData.escalatedTime > 0 || escalated || sosData.tier === 'TWO'}
                    onClick={this.showEscalateOption}
                  >
                    Escalate
                  </Button>
                </ButtonWrapper>
              </div>
            )}
            {showingCloseOption && (
              <div>
                <b>Confirm SOS Close</b>
                <br />
                Are you sure you want to close this SOS?
                <br />
                <br />
                <ButtonWrapper>
                  <Button style={buttonStyle} onClick={this.cancelCloseOption}>
                    Cancel
                  </Button>
                  <Button onClick={() => this.closeSosAlert(sosData.id)} type="primary">
                    Close
                  </Button>
                </ButtonWrapper>
              </div>
            )}
            {showingEscalateOption && (
              <div>
                <b>Confirm SOS Escalation</b>
                <br />
                Are you sure you want to escalate this SOS?
                <br />
                <br />
                <ButtonWrapper>
                  <Button style={buttonStyle} onClick={this.cancelEscalateOption}>
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    disabled={sosData.escalatedTime > 0 || escalated}
                    onClick={() => this.escalateSosAlert(sosData.id)}
                  >
                    Escalate
                  </Button>
                </ButtonWrapper>
              </div>
            )}
          </BodyWrapper>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return <div>{this.renderSosContent(this.props.sosData)}</div>;
  }
}

export const notificationsToRemove = ids => {
  const removeNotifications = notificationsArray.filter(item => !ids.includes(item));
  for (let i = 0; i < removeNotifications.length; i += 1) {
    notification.removeNotice(removeNotifications[i]);
  }
};

const keyWrapper = (sosData, key, isTierTwo, dateFormat, timezone) => {
  const mapStateToProps = state => {
    return {
      sosTrack: getTrackById(state, sosData.trackIdentifier),
      dateFormat: getDateTimeFormat(state),
      timezone: getTimezone(state)
    };
  };
  const mapDispatchToProps = {
    setSelectedTracks: setSelectedTracksAction,
    setSelectedTrackId: setSelectedTrackId,
    selectedMarkerPoint: selectedMarkerPointAction,
    navigateToRoute: push
  };
  const ConnectedSosComponent = connect(mapStateToProps, mapDispatchToProps)(SosComponent);

  return (
    <Provider store={store}>
      <ConnectedSosComponent
        keyData={key}
        sosData={sosData}
        isTierTwo={isTierTwo}
        dateFormat={dateFormat}
        timezone={timezone}
      />
    </Provider>
  );
};

export const openNotification = (sosData, isTierTwo) => {
  if (notificationsArray.includes(sosData.id) && !isTierTwo) {
    return;
  }

  setTimeout(() => {
    playTune(Tune.SOS);
    const key = sosData.id;
    notificationsArray.push(key);
    notification.notice({
      content: keyWrapper(sosData, key, isTierTwo),
      style: popupStyles,
      duration: null,
      key
    });
  }, 500);
};

SosComponent.defaultProps = {
  keyData: () => 0,
  sosData: () => {}
};

SosComponent.propTypes = {
  keyData: PropTypes.string,
  sosData: PropTypes.instanceOf(Object),
  sosTrack: PropTypes.instanceOf(Object),
  setSelectedTracks: PropTypes.func,
  setSelectedTrackId: PropTypes.func,
  setSelectedTracksAction: PropTypes.func,
  selectedMarkerPoint: PropTypes.func,
  navigateToRoute: PropTypes.func,
  isTierTwo: PropTypes.any,
  dateFormat: PropTypes.string,
  timezone: PropTypes.string,
  trackId: PropTypes.number
};

export default openNotification;
