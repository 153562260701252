import { DisplayPoint } from '../common/api/spidertracks-sdk/types/TrackData';
import { CompleteFlightDataSamples } from '../types/FlightData';
import { EventClass, FlightStatus } from '../constants';
import { FullState } from '../store';
import { AircraftBase } from '../types/aircraft';
import { Track } from '../types/Track';

export interface AircraftState {
  aircraftList: Aircraft[];
  visibleAircraft: string[];
  selectedAircraft: string[];
  selectedTrackId: string | undefined;
  trackData: Track[];
  isLoading: boolean;
}

// NOTE: has a very particular format in the API response, probably not a good candidate for a
// "general" organisation type
export interface AircraftOrganisation {
  id: string;
  isPublic: boolean;
  orgName: string;
}

export interface Aircraft extends AircraftBase {
  includes(id: string): unknown;

  isActive: boolean;
  mostRecentTrack: Track;
  trackStatus: string;
  lastPointTimestamp: number;
  org: AircraftOrganisation;
}

// NOTE: duplicates src/types/Track
// export interface Track {
//   aircraft: Aircraft;
//   id: number; // for DB use only
//   trackId: string;
//   points: Point[];
// }

export interface Point {
  longitude: number;
  latitude: number;
  id: number | string;
  timestamp: number;
  altitude: number;
  altitudeUnit?: string;
  pointType?: string;
}

export interface SosNotification {
  id: string;
  tier: 'ONE' | 'TWO';
}

export type MapState = FullState['mapReducer'];

export type NavigationState = FullState['navigationReducer'];

export type UserData = NavigationState['userData'];

// TODO: duplicates common/api/spidertracks-sdk/types/Event
export interface Event {
  aircraftId: string;
  eventClass: EventClass;
  eventId: string;
  eventDetails: Record<string, any>;
  eventTime: string;
  eventType: string;
  geohash: string;
  organisationId: string;
}

export interface CASEvent extends Event {
  eventDetails: Record<string, any> & {
    crewAlertEvent: {
      severity: 'WARNING' | 'CAUTION' | 'ADVISORY';
      subtype: 'ASSERTED' | 'CLEARED';
    };
  };
}

export interface EventState {
  aircraft: Record<string, Event>;
  flightStatus: Record<string, FlightStatus>;
  selectedDisplayPoint: DisplayPoint | undefined;
  // TODO: fix
  types: Record<string, any>;
}

export enum PanelVisibility {
  VISIBLE = 'VISIBLE',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED'
}

export interface FlightExplorer3DReplayFeatures {
  showEvents: boolean;
  showWall: boolean;
}

export type PanelVisibilityKeys =
  | 'flightEventsTimeline'
  | 'flightReportSummaryChart'
  | '2dmap'
  | '3dfr';

export interface ChartSeries {
  left: string[];
  right: string[];
}

export interface FlightExplorerState {
  isPlaying: boolean;
  playbackSpeed: number;
  elapsedTime: number;
  totalTime: number;
  updateSelectedDisplayPoint: boolean;
  displayTimezone: string;
  events: {
    selectedDisplayPoint: Point | null;
  };
  panelVisibility: {
    flightEventsTimeline: PanelVisibility;
    flightReportSummaryChart: PanelVisibility;
    '2dmap': PanelVisibility;
    '3dfr': PanelVisibility;
  };
  '3dReplayFeatures': FlightExplorer3DReplayFeatures;
  selectedTrackFlightData: CompleteFlightDataSamples;
  isFlightDataFetchingInProgress: boolean;
  chartSeries: ChartSeries;
  // TODO Remove once GA
  dataSource: string;
}

export interface TrackStatusPayload {
  trackStatus: TrackStatus[];
  init?: boolean;
}

export interface TrackStatus {
  id: number;
  '3dReplayAvailable': boolean;
}

export interface FilterConfig {
  aircraft?: string[];
  aircraftMoved?: boolean;
  sos?: ('Active' | 'Closed')[];
  spiderTimeGreaterThanOneMinute?: boolean;
}

export interface HistoryTracksState {
  trackStatus: TrackStatus[];
  filterConfig: FilterConfig;
}
