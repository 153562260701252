import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Menu, Tag } from 'antd';
import OrgDropDown from './OrgDropDown';
import { useParams } from 'react-router';
import { ORGANISATION, BeaconGreenHex } from '../../common/constants';
import { useFeatureFlag } from '../../hooks';
import { getInstance } from '../../common/api/spidertracks-sdk';

const { SubMenu } = Menu;
const menuStyle = {
  height: '100%',
  width: '20%',
  maxWidth: '325px',
  minWidth: '230px',
  borderRight: '1px solid #e8e8e8',
  overflow: 'scroll'
};
const subMenuStyle = {
  fontWeight: 'bold',
  margin: '0'
};
const menuItemStyle = {
  margin: '0'
};

export const OrgSettingsMenu = ({ activeSettingsPageMenuKey }) => {
  const { organisationId } = useParams();
  const SpidertracksSDK = getInstance();

  const orgHasInsights = useFeatureFlag('insights', [organisationId]);

  const isEventsEnabled = orgHasInsights;

  const isPublicAircraftSharingBetaFlagEnabled = useFeatureFlag('public-aircraft-sharing', [
    organisationId
  ]);
  const isGeofenceFlagEnabled = useFeatureFlag('geofence', [organisationId]);
  const isNotificationsAdminFlagEnabled = useFeatureFlag('notifications-admin', [organisationId]);
  const isTagsAdminFlagEnabled = useFeatureFlag('manifest-tags-admin', [organisationId]);
  const isLegacyAircraftAndSpidersPageEnabled = useFeatureFlag('legacy-aircraft-and-spiders-page', [
    organisationId
  ]);

  const [orgSpiders, setOrgSpiders] = useState([]);
  const showGPIOPage = useMemo(
    () => orgSpiders.some(spider => spider.spiderType === 'S7' || spider.spiderType === 'S8'),
    [orgSpiders]
  );

  const fetchSpiders = async () => {
    try {
      const spiders = await SpidertracksSDK.getSpiderSettingsService().getSpiders(organisationId);
      setOrgSpiders(spiders);
    } catch (e) {
      console.log(e);
      setOrgSpiders([]);
    }
  };

  useEffect(() => {
    fetchSpiders();
  }, [organisationId]);

  return (
    <div style={menuStyle}>
      <OrgDropDown />
      <Menu
        defaultOpenKeys={['sub1', 'sub2', 'sub3', 'sub4']}
        mode="inline"
        selectedKeys={[activeSettingsPageMenuKey]}
        style={{ borderRight: 'none', margin: '0' }}
      >
        <SubMenu
          key="sub1"
          title={
            <span>
              <span style={subMenuStyle}>Organisation</span>
            </span>
          }
        >
          <Menu.Item style={menuItemStyle} key="basicinformation">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/basicinformation`}>
              Basic Information
            </NavLink>
          </Menu.Item>
          <Menu.Item style={menuItemStyle} key="kml">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/kml`}>Map Overlays</NavLink>
          </Menu.Item>
          <Menu.Item style={menuItemStyle} key="spidertxt">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/spidertxt`}>Spidertxt</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub2"
          title={
            <span>
              <span style={subMenuStyle}>People</span>
            </span>
          }
        >
          <Menu.Item style={menuItemStyle} key="members">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/members`}>Users</NavLink>
          </Menu.Item>
          <Menu.Item style={menuItemStyle} key="visibility">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/visibility`}>
              Visibility
            </NavLink>
          </Menu.Item>
          {isTagsAdminFlagEnabled && (
            <Menu.Item style={menuItemStyle} key="tags">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/tags`}>Tags</NavLink>
            </Menu.Item>
          )}
          <Menu.Item style={menuItemStyle} key="notifications">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/notifications`}>
              Notifications
            </NavLink>
          </Menu.Item>
          {isNotificationsAdminFlagEnabled && (
            <Menu.Item style={menuItemStyle} key="notifications-beta">
              <NavLink
                to={`${ORGANISATION}/${organisationId}/settings/notifications-beta/geofences`}
              >
                Notifications{' '}
                <Tag color={BeaconGreenHex} style={{ color: 'black' }}>
                  SpiderX
                </Tag>
              </NavLink>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub3"
          title={
            <span>
              <span style={subMenuStyle}>Aircraft</span>
            </span>
          }
        >
          {isLegacyAircraftAndSpidersPageEnabled && (
            <Menu.Item style={menuItemStyle} key="aircraftsettings">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/aircraftsettings`}>
                Aircraft & Spiders
              </NavLink>
            </Menu.Item>
          )}
          <Menu.Item id="spiders" key="spiders">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/spiders`}>
              Aircraft & Spiders
              {isLegacyAircraftAndSpidersPageEnabled && (
                <Tag color={BeaconGreenHex} style={{ color: 'black', marginLeft: '5px' }}>
                  SpiderX
                </Tag>
              )}
            </NavLink>
          </Menu.Item>
          {isEventsEnabled && (
            <Menu.Item id="events" key="events">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/events`}>Events</NavLink>
            </Menu.Item>
          )}
          {isGeofenceFlagEnabled && (
            <Menu.Item id="geofence" key="geofence">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/geofence`}>Geofence</NavLink>
            </Menu.Item>
          )}
          <Menu.Item style={menuItemStyle} key="sossettings">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/sossettings`}>SOS</NavLink>
          </Menu.Item>
          {showGPIOPage && (
            <Menu.Item data-testid="eventlogging" style={menuItemStyle} key="eventlogging">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/eventlogging`}>GPIO</NavLink>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub4"
          title={
            <span>
              <span style={subMenuStyle}>Integrations</span>
            </span>
          }
        >
          <Menu.Item key="manageapps">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/manageapps`}>
              Manage Apps
            </NavLink>
          </Menu.Item>
          <Menu.Item key="aff">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/aff/api`}>AFF API</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="sub5"
          title={
            <span>
              <span style={subMenuStyle}>Share</span>
            </span>
          }
        >
          <Menu.Item style={menuItemStyle} key="publicpage">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/publicpage`}>
              Public Page
            </NavLink>
          </Menu.Item>
          {isPublicAircraftSharingBetaFlagEnabled && (
            <Menu.Item id="publicaircraft" key="publicaircraft">
              <NavLink to={`${ORGANISATION}/${organisationId}/settings/publicaircraft`}>
                Public Aircraft
              </NavLink>
            </Menu.Item>
          )}
        </SubMenu>
        <SubMenu
          key="sub6"
          title={
            <span>
              <span style={subMenuStyle}>Billing</span>
            </span>
          }
        >
          <Menu.Item style={menuItemStyle} key="billingandpayments">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/billingandpayments`}>
              Billing and Payments
            </NavLink>
          </Menu.Item>
          <Menu.Item style={menuItemStyle} key="invoices">
            <NavLink to={`${ORGANISATION}/${organisationId}/settings/invoices`}>Invoices</NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};

OrgSettingsMenu.propTypes = {
  activeSettingsPageMenuKey: PropTypes.string
};

export default OrgSettingsMenu;
